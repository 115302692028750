<template>
  <v-row justify="center" justify-sm="end">
    <v-col cols="auto">
      <v-btn :disabled="disabled" :loading="loading" :small="$vuetify.breakpoint.xsOnly" color="secondary" outlined
             @click="clone">
        {{ $vuetify.lang.t('$vuetify.pages.dashboard.profile.revertChangesBtn') }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn :disabled="disabled" :loading="loading" :small="$vuetify.breakpoint.xsOnly" color="secondary" outlined
             @click="save">
        {{ $vuetify.lang.t('$vuetify.pages.dashboard.profile.saveChangesBtn') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KurccProfileActionButtons',
  props: {
    clone: Function,
    save: Function,
    loading: Boolean,
    disabled: Boolean
  }
}
</script>
